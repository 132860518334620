import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}><h3>{education.school}</h3>
          <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
          <p>{education.description}</p></div>
      })
      var work = this.props.data.work.map(function (work) {
        return <div key={work.company}><h3>{work.company}</h3>
          <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
          {work.description}
        </div>
      })
      var skills = this.props.data.skills.map(function (skills) {
        var className = 'bar-expand ' + skills.name.toLowerCase();
        return <li key={skills.name}><span style={{ width: skills.level }} className={className}></span><em>{skills.name}</em></li>
      })
    }

    return (
      <section id="resume">

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                {education}
              </div>
            </div>
          </div>
        </div>


        <div className="row work">

          <div className="three columns header-col">
            <h1><span>Experience</span></h1>
          </div>

          <div className="nine columns main-col">
            {/* {work} */}
            <div key="Amazon"><h3>Amazon</h3>
              <p className="info">System Development Engineer<span>&bull;</span> <em className="date">January 2018 - Present</em></p>
              <ul>
                <li>&bull; Automated hundreds of deployments into specialized regions that support the EC2 service in AWS</li>
                <li>&bull; Architected and programmed automation tools using AWS services that monitor service health and configure continuous deployments</li>
                <li>&bull; Reviewed dozens of pipelines to ensure they are operationally excellent and meet the bar for continuous deployment</li>
                <li>&bull; Mentored associates by teaching them operational and software paradigms and trained them up to become engineers</li>
              </ul>
            </div>
            <div key="Lockheed Martin"><h3>Lockheed Martin</h3>
              <p className="info">Software Engineer<span>&bull;</span> <em className="date">August 2017 - December 2017</em></p>
              <ul>
                <li>&bull; Simulated and optimized a satellite coverage algorithm for 291 space vehicles which allocated service to over 4000 user areas across the globe</li>
                <li>&bull; Programmed a Network Operations Center GUI that uses the Leaflet Maps API to visualize satellite global coverage</li>
              </ul>
            </div>
            <div key="Alfred Gessow Rotorcraft Center"><h3>Alfred Gessow Rotorcraft Center</h3>
              <p className="info">Software Engineer<span>&bull;</span> <em className="date">August 2015 - July 2017</em></p>
              <ul>
                <li>&bull; Programmed a real-time wind tunnel simulator and free flight simulator for virtual helicopters with an elastic rotor model</li>
                <li>&bull; Co-authored a paper on “A Python-based Framework for Real-time Simulation using Comprehensive Analysis” which was presented at AHS International's 72nd Annual Forum</li>
                <li>&bull; Invited to present a seminar on real-time simulations with comprehensive analysis at the Army Research Laboratory</li>
                <li>&bull; Presented a paper on “A Python-based Framework for a Real-time Wind Tunnel Rotor Simulation using Comprehensive Analysis” at the 2016 AIAA Region I Student Conference</li>
                <li>&bull; Presented a paper on “A Modular Framework for a Virtual Reality Capable Rotorcraft Simulation” at the 2017 AIAA Region I Student Conference</li>
                <li>&bull; Presented research findings and updates to research fellows and graduate assistants at weekly meetings</li>
              </ul>
            </div>
            <div key="U.S. Army Research Lab"><h3>U.S. Army Research Lab</h3>
              <p className="info">Computer Engineer<span>&bull;</span> <em className="date">May 2016 - December 2016</em></p>
              <ul>
                <li>&bull; Linked C# and Python scripts in the Unity Game Editor to pioneer a virtual reality capable rotorcraft simulator to assess performance of new vehicle designs using comprehensive analysis</li>
                <li>&bull; Automated high-fidelity modeling and simulation work flow through parallelization techniques</li>
                <li>&bull; Prepared and presented a presentation/demo of a Real-time Rotorcraft Flight Simulator with Comprehensive Analysis</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">

            <p>{skillmessage}
            </p>

            <div className="bars">
              <ul className="skills">
                {skills}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
